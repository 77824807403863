<template>
  <div v-if="user">
    <h1 style="text-align: center;">User Dashboard</h1>
    <h2 style="text-align: center;">Welcome! {{ user.DisplayName }}</h2>
    <button @click="logoutUser">Logout</button>
    <h1>Place Order</h1>
    <p>Product:</p>
    <input type="text" v-model="orderData.productId" />
    <p>Amount:</p>
    <input type="text" v-model="orderData.amount" />
    <br />
    <button @click.prevent="makePayment">Pay now</button>
    <div v-if="paymentStatus">
      <h3>
        {{ paymentStatus }}
      </h3>
    </div>
  </div>
  <div v-else>
    Please wait... while loading settings..
    <button @click.prevent="getUserDetails">Retry</button>
  </div>
</template>

<script>
import { LogIn, logout } from "@/data/auth";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { myhttp } from "@/data/myhttp";
export default defineComponent({
  setup() {
    const router = useRouter();
    const user = ref(null);
    onMounted(() => {
      getUserDetails();
    });
    function getUserDetails() {
      user.value = LogIn(user);
      console.log(user.value);
    }
    function logoutUser() {
      logout();
      router.push({ name: "Home" });
    }
    //Order:
    const paymentStatus = ref(null);
    const orderData = reactive({ productId: 3, currencyId: 1, amount: 32.12 });
    function makePayment() {
      let data = JSON.parse(JSON.stringify(orderData));
      data.amount = parseFloat(data.amount);
      data.productId = parseInt(data.productId);
      // 1. GENERATE ORDER_ID using razorpay/order api
      myhttp
        .post("payment/OrderGenerate/", data)
        .then((resp) => {
          var rzp1 = new window.Razorpay({ ...resp.data, handler: handler });
          rzp1.open();
        })
        .catch((err) => {
          console.log("ERR", err);
        });
    }
    function verifySignature(response) {
      myhttp
        .post("payment/verify", response)
        .then((res) => {
          if (res.data) {
            paymentStatus.value = "Payment Successfull";
          } else {
            paymentStatus.value = "Payment failed";
          }
          console.log("PAYMENT RESPONSE", res);
        })
        .catch((err) => {
          paymentStatus.value = "Payment failedd";
          console.log("error" + err);
        });
    }
    function handler(response) {
      verifySignature(response);
    }
    return {
      user,
      logoutUser,
      getUserDetails,
      orderData,
      makePayment,
      paymentStatus,
    };
  },
});
</script>

<style></style>
